import * as React from "react";
import { graphql } from "gatsby";
import { Box, Flex } from "@chakra-ui/react";
import SeoComponent from "../components/SeoComponent";
import Loading from "../components/Loading";

/* SECTIONS */
import HomeHero from "../sections/HomeHero";
import TheWanaWay from "../sections/TheWanaWay";
import ShopByCategory from "../sections/ShopByCategory";
import WanaLearn from "../sections/WanaLearn";
// import ThreeCategoriesWideHome from "../sections/ThreeCategoriesWideHome";
import DropdownEffectHome from "../sections/DropdownEffectHome";
// import EducationHome from "../sections/EducationHome";
// import VideoHome from "../sections/VideoHome";
import ComboFindWanaHome from "../sections/ComboFindWanaHome";
import { FeaturedContentHome } from "../sections/FeaturedContentHome";
// import GummiesSubcategories from "../sections/GummiesSubcategories";
import WanaBrand from "../sections/WanaBrand";

/* IMPORT APP CONTEXT */
import { AppContext } from "../context";

const isBrowser = typeof window !== "undefined";

function IndexPage(props) {
  const { data, location } = props;
  const homeRef = React.useRef(null);
  const appContext = React.useContext(AppContext);

  /* GET SECTIONS DATA */
  const [sections, setSections] = React.useState(null);
  const seoImg = data.sanityPage.seoImage?.image || (sections as any)?.heroHome?.bgImage;

  React.useEffect(() => {
    /* SCROLL TO TOP */
    homeRef.current ? window.scrollTo(0, homeRef.current.offsetTop) : null;

    if (!appContext.homeSections) {
      /* GET SECTIONS DATA */
      const sectionsObj = {};

      data.sanityPage.sections.map((section, indx) => {
        section ? (sectionsObj[section._type] = section) : null;
      });

      setSections(sectionsObj);
      appContext.actions.updateHomeSections(sectionsObj);
    } else {
      setSections(appContext.homeSections);
    }
  }, []);

  return (
    <Box>
      <SeoComponent
        title={data.sanityPage.seoTitle}
        description={data.sanityPage.seoDescription}
        imageUrl={seoImg?.asset?.url}
      />

      {sections && (
        <Box ref={homeRef}>
          {/* HERO HOME */}
          <HomeHero {...sections.heroHome} />

          {/* THE WANA WAY */}
          <TheWanaWay />

          {/* SHOP BY CATEGORY */}
          <ShopByCategory />

          {/* Wana Brand */}
          <WanaBrand />

          {/* GUMMIES SUBCATEGORIES */}
          {/* <GummiesSubcategories /> */}

          {/* CATEGORIES */}
          {/* <ThreeCategoriesWideHome {...sections.productCategoriesHome} /> */}

          {/* DROPDOWN EFFECTS */}
          <DropdownEffectHome />

          {/* WANA LEARN */}
          <WanaLearn />

          {/* EDUCATION */}
          {/* <EducationHome /> */}

          {/* VIDEO */}
          {/* <VideoHome {...sections.videoHome} /> */}

          {/* FIND WANA */}
          <ComboFindWanaHome {...sections.findWanaHome} />

          {/* FEATURED CONTENT */}
          <FeaturedContentHome inHomePage />
        </Box>
      )}

      {!sections && (
        <Flex
          w="100%"
          minH="100vh"
          mx="auto"
          justifyContent="center"
          alignItems="center"
        >
          <Loading />
        </Flex>
      )}
    </Box>
  );
}

export const query = graphql`
  query {
    sanityPage(slug: { current: { eq: "/" } }) {
      _id
      slug {
        current
      }
      seoTitle
      seoDescription
      seoImage {
        image {
          asset {
            url
          }
        }
      }
      sections {
        ... on SanityHeroHome {
          _key
          _type
          accentHeader {
            headerText
            textColor {
              color
            }
          }
          mainHeader {
            headerText
            textColor {
              color
            }
          }
          italicHeader {
            headerText
            textColor {
              color
            }
          }
          bgImage {
            asset {
              _id
              id
              url
              altText
              gatsbyImageData(placeholder: NONE, fit: FILL, width: 6000)
            }
          }
          bgImageTablet {
            asset {
              _id
              id
              url
              altText
              gatsbyImageData(placeholder: NONE)
            }
          }
          bgImageMobile {
            asset {
              _id
              id
              url
              altText
              gatsbyImageData(placeholder: NONE)
            }
          }
          ctaLeft {
            text
            isSolid
            textColor {
              color
            }
            bgColor {
              color
            }
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            linkToPage {
              slug {
                current
              }
            }
          }
          ctaLeftModal
          ctaRight {
            isSolid
            text
            textColor {
              color
            }
            bgColor {
              color
            }
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            linkToPage {
              slug {
                current
              }
            }
          }
          curveText
        }
        ... on SanityProductCategoriesHome {
          _key
          _type
          header {
            headerText
            textColor {
              color
            }
          }
          headerWarning {
            headerText
            textColor {
              color
            }
          }
          additionalProducts {
            categoryName {
              headerText
              textColor {
                color
              }
            }
            categoryDescription {
              headerText
              textColor {
                color
              }
            }
            cta {
              text
              textColor {
                color
              }
              bgColor {
                color
              }
              ctaRoute {
                routeName
                slug {
                  current
                }
                route
              }
              linkToPage {
                slug {
                  current
                }
              }
            }
            image {
              asset {
                gatsbyImageData(placeholder: NONE)
                altText
              }
            }
          }
        }
        ... on SanityVideoHome {
          _key
          _type
          coverImage {
            asset {
              altText
              gatsbyImageData(placeholder: NONE)
            }
          }
          header {
            headerText
            textColor {
              color
            }
          }
          description
          cta {
            text
            textColor {
              color
            }
            bgColor {
              color
            }
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            linkToPage {
              slug {
                current
              }
            }
          }
          linkToContent {
            slug {
              current
            }
          }
          videoURL
          parallaxImgs {
            velocity
            image {
              asset {
                url
              }
            }
          }
        }
        ... on SanityFindWanaHome {
          _key
          _type
          header {
            headerText
            textColor {
              color
            }
          }
          description
          mainImage {
            asset {
              altText
              gatsbyImageData(
                fit: SCALE
                width: 1000
                height: 976
                layout: FULL_WIDTH
                placeholder: NONE
              )
            }
          }
          cta {
            text
            textColor {
              color
            }
            bgColor {
              color
            }
            ctaRoute {
              routeName
              slug {
                current
              }
              route
            }
            linkToPage {
              slug {
                current
              }
            }
          }
          parallaxImgs {
            velocity
            image {
              asset {
                url
              }
            }
          }
        }
      }
    }
    allSanityProductCategory(sort: { fields: _createdAt }) {
      nodes {
        name
        description
        slug {
          current
        }
        coverImage {
          asset {
            gatsbyImageData(width: 552, height: 471, placeholder: NONE)
          }
        }
        catColor {
          color
        }
        subcategories {
          name
          description
          slug {
            current
          }
          coverImage {
            asset {
              gatsbyImageData(placeholder: NONE)
            }
          }
          catColor {
            color
          }
          category {
            slug {
              current
            }
          }
        }
      }
    }
  }
`;
export default IndexPage;
